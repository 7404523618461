/* variables declared here - these are the colors for our pages, as well as the font stacks and sizes. */
:root {
  --white: #f7f8fa;
  --teal: #64edc6;
  --baby-blue: #4ed0fa;
  --indigo: #6985e0;
  --purple: #9268f7;
  --violet: #d664ed;
  --black: #171321;
  --font-size: 1.3rem;
  --mono: Roboto Mono, monospace;
  --sans: Roboto Condensed, sans-serif;
}
/* border box model: https://css-tricks.com/box-sizing/ */
html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

#app {
  padding: 0;
  margin: 0;
  font-family: var(--sans);
  background-color: var(--black);
  color: var(--white);
  font-size: var(--font-size);
}
h1,
h2,
h3,
h4 {
  margin: 0;
}
#js a:hover {
  color:transparent;
}
a {
     color: var(--baby-blue); 
}
a:hover {
  color: var(--purple);
  text-decoration: none;
}

img {
  width: 100%;
}  



/* intro styles */
#intro {
  padding: 4rem 1rem 10rem 1rem;
  max-width: 1200px;
  margin: 0 auto
}
#intro p {
  font-size: 1rem;
  line-height: 1.5;
}

#intro .name {
  font-family: var(--mono);
  font-size: 1rem;
}

.name span {
  font-family: var(--sans);
  font-size: 4rem;
  color: var(--baby-blue);
  display: block;
  font-weight: 300;
}

#intro h2 {
  font-size: 4rem;
  font-weight: normal;
}

#about-page #intro h2 {
  color: pink;
}

.div-violet {
  background-color: var(--violet);
}

.div-teal {
  background-color: var(--teal);
}

.gradient-90 {
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(214,100,237,0.6208858543417367) 19%, rgba(0,212,255,1) 100%);  
   height: 3px;
}

.gradient-270 {
  background: linear-gradient(270deg, rgba(2,0,36,1) 0%, rgba(214,100,237,0.6208858543417367) 20%, rgba(0,212,255,1) 100%);
height: 3px}


.black-box {
  background-color: var(--black);
padding: 1rem;
border-radius: 10px;
color: var(--white);
font-size: 1rem;
line-height: 1.5;
}

#css:hover {
 background-image: url("/public/images/css.png"); 
  background-position: center;
  background-size: contain; 
  background-repeat: no-repeat;   

  }



#js:hover {
  background-image: url("/public/images/js.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;   }

  #html:hover {
    background-image: url("/public/images/html.png");
    background-position: center;
    background-size: contain;
      background-repeat: no-repeat;   

    }

    #node:hover {
      background-image: url("/public/images/node.png");
      background-position: center;
      background-size: contain;
      }

      #sql:hover {
        background-image: url("/public/images/sql.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;        }

        #redux:hover {
          background-image: url("/public/images/redux.png");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;          }

          #filestack:hover {
            background-image: url("/public/images/filestack.png");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            }
            #artic:hover {
              background-image: url("/public/images/artic.png");
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              }


.white-box {
  background-color: var(--white);
padding: 1rem;
border-radius: 10px;
color: var(--black);
font-size: 1rem;
line-height: 1.5;
}

.white-box:hover {
  color: transparent;
 
}

.yellow-box {
  background-color: pink;
padding: 1rem;
border-radius: 10px;
color: var(--black);
font-size: 1rem;
line-height: 1.5;
}


#contact {
  width: 400px;
  text-align: center;
  margin: 0 auto;
  padding: 4rem 0;
  /*   font-style: var(--sans); */
  /*   background-color: var(--purple); */

  /* padding: 3rem 0; */
}


#about {
  padding: 4rem 1rem;
  
}


#contact p:last-child {
  margin-top: 3rem;
}

nav {
  font-family: var(--mono);
  font-size: 80%;
  padding: 4rem 1rem;
}

footer {
  text-align: center;
  font-weight: normal;
/*   font-family: var(--sans);
  font-size: 80%;*/
padding: 4rem 0; 
}

nav h1 a {
  font-family: var(--sans);
  font-weight: normal;

  /* can also just say sans   */
}


footer h2 {
  font-family: var(--sans);
  font-weight: normal; 
}


 

nav ul {
  list-style-type: none;
  /*  can also say list-style  */
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

footer ul {
  list-style-type: none;
  /*  can also say list-style  */
  padding: 0;
  margin: 2rem 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 3rem;
  font-size: 3rem;
  
}


nav li:first-child {
  flex-basis: 100%;
  /* can also do this
  flex: 0 0 100%;
(this means... flex-grow flex-grow flex-basis)*/
  text-align: center;
}

/* footer li {
  flex-basis: 100%;
  
  text-align: center;
}
 */

nav [class*="fa-"] {
  font-size: 150%;
  color: var(--baby-blue);
  display: block;
}

/* footer [class*="fa-"] {
  font-size: 150%;
  color: var(--baby-blue);
  display: inline;
} */


nav h1 [class*="fa-"] {
  font-size: 100%;
  color: hotpink;
  /*     var(--baby-blue); */
}

/* footer h2 [class*="fa-"] {
  font-size: 100%;
  color: hotpink;
  
}
 */

nav a {
  color: white;
  /*   color: var(--white); */
  text-decoration: none;
  display: block;
}

/* footer a {
  color: white;
  
  text-decoration: none;

}

 */
nav a:hover,
nav [class*="fa-"]:hover {
  color: var(--purple);
}

/* footer a:hover,
footer [class*="fa-"]:hover {
  color: var(--purple);
} */


.button {
  background-color: var(--purple);
  color: var(--white);
  font-family: var(--mono);
  padding: .5rem;
  border-radius: 5px;
  text-decoration: none;
}



.button:hover {
  color: var(--white);
   background-color: var(--purple); 
}

#projects {
  padding: 4rem 1rem;
}

/* #projects {
  padding: 4rem 1rem;
} */

#projects h2, h3, h4 {
  color: black;
}

#about h2, h3, h4 {
  color: black;
}

#projects h2 {
  font-size: 2.5rem;
  margin-bottom: calc(2.5rem * 1.5);
}

#about h2 {
  font-size: 2.5rem;
  margin-bottom: calc(2.5rem * 1.5);
}


#projects h3 {
  color: var(--indigo)
/*   font-family: var(--mono);
  text-align: right; */
}

#about h3 {
  color: var(--indigo)
/*   font-family: var(--mono);
  text-align: right; */
}


#projects h4 {
  font-size: 1rem;
  font-family: var(--mono);
  margin: 0;
}

#about h4 {
  font-size: 1rem;
  font-family: var(--mono);
  margin: 0;
}

#projects ul {
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0;
  margin: 0, auto;
  gap: 1rem;
  font-size: 1rem;
  color: red;
}

#about ul {
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0;
  margin: 0, auto;
  gap: 1rem;
  font-size: 1rem;
  color: red;
}

#projects img {
  margin: 2rem 0 4rem 0;
  border-left: 1px solid var(--teal);
  border-top: 1px solid var(--teal);
  border-radius: 25px;
  padding: 1rem;
  grid-column: 1/6;
  grid-row: 1/2;
}

  #about img {
    margin: 2rem 0 4rem 0;
    border-left: 1px solid var(--teal);
    border-top: 1px solid var(--teal);
    border-radius: 25px;
    padding: 1rem;
    grid-column: 1/6;
    grid-row: 1/2;
}

@media (min-width: 550px) {
  article {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 1rem;
  }
  
  .text {
    grid-row: 1/2;
    grid-column: 5/11;
   order: 2;
    text-align: right;
  }
  #projects .reverse .text {
    grid-column: 1/7;
    order: 2;
    text-align: left;
  }

  #about .reverse .text {
    grid-column: 1/7;
    order: 2;
    text-align: left;
  }
  
  #projects .reverse img {
    grid-column: 6/11;
    grid-row: 1/2; 
    
  }

  #about .reverse img {
    grid-column: 6/11;
    grid-row: 1/2; 
    
  }
  
  #projects ul {
    justify-content: flex-end;
  }
  
  #projects .reverse ul {
    justify-content: flex-start;
  }

  #about ul {
    justify-content: flex-end;
  }
  
  #about .reverse ul {
    justify-content: flex-start;
  }
}



@media (min-width: 800px) {
    #projects {
        max-width: 1200px;
        margin: 0 auto;
    }
  article {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
  }
  #projects img {
    grid-column: 1/6;
    grid-row: 1/2;
  }

  #about img {
    grid-column: 1/6;
    grid-row: 1/2;
  }
  .text {
    grid-column: 5/11;
    grid-row: 1/2;
    order: 2;
    text-align: right;
  }
  #projects ul {
    justify-content: flex-end;
  }
  #about ul {
    justify-content: flex-end;
  }
  
}



@media (min-width: 850px) {
  nav {
    max-width: 1200px;
    margin: 0 auto;
  } 
  nav li:first-child {
    flex-basis: auto;
    text-align: left;
    margin-right: auto;
  }
}

/* footer:first-child {
   flex-basis: auto;
    text-align: left;
    margin-right: auto; 
  
  } */



/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
